import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  CemexTetris1,
  CemexTetris2,
  CemexTetris3,
  CemexTetris4
} from "../../assets/images/Images";
import { Caption, CaptionWrapper } from "../../components/Caption";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import InnerHTML from "../../components/InnerHTML";
import Spacer from "../../components/Spacer";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";

const TetrisSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer mobileSize="extraSmall" size="extraLarge" />
      <GridWrapper>
        <Block width={8} paddingRight={20}>
          <Caption>
            <InnerHTML
              content={putEmptyLineInsteadSpace(
                intl.formatMessage({ id: "cemex.tetris_section.text_1" }),
                4
              )}
            />
          </Caption>
        </Block>
        <Block width={4} paddingLeft={10}>
          <Image src={CemexTetris1} alt="Cemex" />
        </Block>
        <Spacer size="small" />
        <Block width={8} paddingRight={10}>
          <Image src={CemexTetris2} withMaxWidth={false} alt="Cemex" />
        </Block>
        <Block width={4} paddingLeft={10}>
          <Image src={CemexTetris3} alt="Cemex" />
        </Block>
        <Spacer size="small" />
        <Block width={1} />
        <Block width={4}>
          <Image src={CemexTetris4} alt="Cemex" />
        </Block>
        <Block width={7} paddingLeft={50}>
          <Caption>
            {intl.formatMessage({ id: "cemex.tetris_section.text_2" })}
          </Caption>
        </Block>
      </GridWrapper>
      <Spacer size="extraLarge" />
      <CaptionWrapper>
        <Caption bold>
          {intl.formatMessage({ id: "cemex.tetris_section.text_3" })}
        </Caption>
      </CaptionWrapper>
      <Spacer size="extraLarge" />
    </Container>
  );
};

export default TetrisSection;
