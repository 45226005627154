import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  CemexBlock1,
  CemexBlock2,
  CemexBlock3
} from "../../assets/images/Images";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import HeadingBlock from "../general/HeadingBlock";
import ImagedBlocks, { ImagedBlockT } from "../general/ImagedBlocks";

const ScheduleSection: FC = () => {
  const intl = useIntl();

  const blocks: ImagedBlockT[] = [
    {
      text: intl.formatMessage({ id: "cemex.schedule_section.block_1" }),
      image: CemexBlock1
    },
    {
      text: intl.formatMessage({ id: "cemex.schedule_section.block_2" }),
      image: CemexBlock2
    },
    {
      text: intl.formatMessage({ id: "cemex.schedule_section.block_3" }),
      image: CemexBlock3
    }
  ];

  return (
    <Container>
      <Spacer size="extraLarge" />
      <HeadingBlock
        heading={intl.formatMessage({ id: "cemex.schedule_section.title" })}
        pre={intl.formatMessage({ id: "cemex.schedule_section.pre" })}
        paragraph={intl.formatMessage({
          id: "cemex.schedule_section.paragraph"
        })}
      >
        <Spacer size="large" />
        <ImagedBlocks blocks={blocks} />
      </HeadingBlock>
      <Spacer mobileSize="small" size="extraLarge" />
    </Container>
  );
};

export default ScheduleSection;
