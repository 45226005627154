import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { CemexGraph, TextureTemplateWhite } from "../../assets/images/Images";
import Container from "../../components/Container";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { SIDEBAR_WIDTH } from "../SideBar";

const OFFSET = 300;

const Wrapper = styled.div`
  ${({ theme }): string[] => [theme.media.maxMD]} {
    display: none;
  }
`;

const TextureWrapper = styled.div`
  background-image: url(${TextureTemplateWhite});
  margin-left: ${rem(-SIDEBAR_WIDTH)};
  padding-left: ${rem(SIDEBAR_WIDTH)};
  text-align: center;
  padding: ${rem(35)} 0 ${rem(OFFSET)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    margin-left: 0;
    padding-left: 0;
  }
`;

const ImageWrapper = styled.div`
  margin-top: ${rem(-OFFSET)};
`;

const Heading = styled.h3`
  position: relative;
  font-size: ${rem(30)};
  line-height: ${rem(50)};
  top: ${rem(-20)};
  font-family: "Overpass Mono";
`;

const Image = styled.img`
  width: 100%;
  max-width: ${rem(1100)};
`;

const ChartSection: FC = () => {
  const intl = useIntl();

  return (
    <Wrapper>
      <TextureWrapper>
        <Heading>
          {intl.formatMessage({ id: "cemex.chart_section.title" })}
        </Heading>
      </TextureWrapper>
      <ImageWrapper>
        <Container>
          <Image
            src={CemexGraph}
            alt={intl.formatMessage({ id: "cemex.chart_section.title" })}
          />
        </Container>
      </ImageWrapper>
    </Wrapper>
  );
};

export default ChartSection;
