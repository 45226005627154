import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  CemexScreen1,
  CemexScreen2,
  CemexScreen3,
  CemexScreen4
} from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Heading from "../../components/Heading";
import Spacer from "../../components/Spacer";

const IntroducedSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer mobileSize="large" size="extraLarge" />
      <Heading
        title={intl.formatMessage({
          id: "cemex.introduced_section.title"
        })}
        pre={intl.formatMessage({
          id: "cemex.introduced_section.pre"
        })}
        symbol={null}
      />
      <Spacer mobileSize="medium" size="large" />
      <GridWrapper>
        <Block paddingRight={50} justifyContent="flex-start">
          <Image src={CemexScreen1} alt="Material design UI" />
        </Block>
        <Block paddingRight={30} paddingLeft={30} justifyContent="flex-start">
          <Spacer mobileSize="large" size="huge" />
          <Image src={CemexScreen2} alt="Ticket based" />
        </Block>
        <Block
          width={5}
          justifyContent="flex-start"
          paddingLeft={50}
          paddingRight={50}
        >
          <Image src={CemexScreen3} alt="Mobile-friendly" />
        </Block>
        <Block justifyContent="flex-start" paddingLeft={30} paddingRight={30}>
          <Image src={CemexScreen4} alt="Typography" />
        </Block>
      </GridWrapper>
    </Container>
  );
};

export default IntroducedSection;
