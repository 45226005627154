import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  CemexLogoFiancial,
  CemexLogoForbes,
  CemexLogoNYT,
  CemexLogoSAP,
  CemexLogoWire
} from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import HeadingBlock from "../general/HeadingBlock";

const OpacityWrapper = styled.div`
  opacity: 0.3;
  ${({ theme }): string[] => [theme.media.maxMD]} {
    max-width: ${rem(250)};
    margin: ${rem(20)} auto;
  }
`;

const AriseSection: FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <Spacer size="extraLarge" />
      <GridWrapper>
        <Block paddingRight={50} justifyContent="flex-start">
          <Image
            src={CemexLogoSAP}
            alt={intl.formatMessage({
              id: "cemex.arise_section.title"
            })}
          />
        </Block>
        <Block paddingRight={50} paddingLeft={30} justifyContent="flex-start">
          <HeadingBlock
            withoutBlock
            heading={intl.formatMessage({
              id: "cemex.arise_section.title"
            })}
            pre={intl.formatMessage({
              id: "cemex.arise_section.pre"
            })}
            paragraph={intl.formatMessage({
              id: "cemex.arise_section.paragraph"
            })}
            symbol={null}
          />
        </Block>
        <Spacer size="extraLarge" />
        <Block width={3.7} paddingLeft={25} paddingRight={25}>
          <OpacityWrapper>
            <Image src={CemexLogoNYT} alt="NYT" />
          </OpacityWrapper>
        </Block>
        <Block width={2.3} paddingLeft={25} paddingRight={25}>
          <OpacityWrapper>
            <Image src={CemexLogoForbes} alt="Forbes" />
          </OpacityWrapper>
        </Block>
        <Block width={3.4} paddingLeft={25} paddingRight={25}>
          <OpacityWrapper>
            <Image src={CemexLogoFiancial} alt="Financial post" />
          </OpacityWrapper>
        </Block>
        <Block width={2.6} paddingLeft={25} paddingRight={25}>
          <OpacityWrapper>
            <Image src={CemexLogoWire} alt="Business Wire" />
          </OpacityWrapper>
        </Block>
      </GridWrapper>
      <Spacer mobileSize="large" size="extraLarge" />
    </Container>
  );
};

export default AriseSection;
