import React, { FC } from "react";

import { CemexLogoFortune, CemexLogoGo } from "../../assets/images/Images";
import Layout from "../../components/Layout";
import AriseSection from "../../partials/Cemex/AriseSection";
import ChartSection from "../../partials/Cemex/ChartSection";
import FoundationSection from "../../partials/Cemex/FoundationSection";
import IntroducedSection from "../../partials/Cemex/IntroducedSection";
import ScheduleSection from "../../partials/Cemex/ScheduleSection";
import TetrisSection from "../../partials/Cemex/TetrisSection";
import EnterSection, { FortuneLogo } from "../../partials/general/EnterSection";
import Introduction, { CemexGoLogo } from "../../partials/general/Introduction";
import SuccessStoriesFooter from "../../partials/general/SuccessStoriesFooter";

const HomePage: FC = () => {
  const ID = "cemex";

  return (
    <Layout
      helmetKey={ID}
      emptyMenu={true}
      blackMenu={true}
      lightColorVariant
      menuIconType="/success-stories"
      render={(): React.ReactChild => (
        <>
          <Introduction
            caseStudyId={ID}
            picture={<CemexGoLogo src={CemexLogoGo} />}
          />
          <EnterSection
            underParagraphPicture={
              <FortuneLogo src={CemexLogoFortune} alt={"Cemex logo"} />
            }
            caseStudyId={ID}
          />
          <FoundationSection />
          <IntroducedSection />
          <ScheduleSection />
          <ChartSection />
          <TetrisSection />
          <AriseSection />
          <SuccessStoriesFooter />
        </>
      )}
    />
  );
};

export default HomePage;
